<template>
    <div class="card">
        <div class="is-half-mobile filter-periods">
            <label class="label">{{ i18n('Filter by period') }}</label>
            <vue-select :source="route('period.options')"
                        v-model="period_selected"
                        :http="http"
                        track-by="id"
                        :placeholder="i18n('Pick an option')"/>
        </div>
      <modal-component initial-title="Import data" ref="modalComponent">
        <import-custom :period-selected="period_selected"
        :variable="selectedVariable"
        :program="selectedProgram"/>
      </modal-component>
        <div class="card-content">
            <table class="table">
                <tr>
                    <th rowspan="2">
                        {{ i18n('Data for liquidation') }}
                    </th>
                    <th colspan="2">
                        {{ i18n('Variable') }}
                    </th>
                </tr>
                <tr>
                    <th>{{ i18n('Quantity') }}</th>
                    <th> {{ i18n('Percentage') }}</th>
                    <th> {{ i18n('Actions') }} </th>
                </tr>
                <tr v-for="variable in filteredVariables" :key="variable.id">
                    <td>
                        {{ variable.name }}
                    </td>
                    <td>{{ variable.liquidationsCount }} / {{ variable.programUsersCount }}</td>
                    <td :class="{'red-cell': variable.percentage <= 50,
                     'yellow-cell': variable.percentage > 50.1 && variable.percentage < 90,
                      'green-cell': variable.percentage >= 90,
                       'blue': variable.color === 'blue'}">
                        {{ Math.round(variable.percentage) }} %
                    </td>
                    <td class="actions">
                        <i class="fas fa-upload import-button"
                           v-if="variable.type !== 'liquidation_by_dependency'"
                           @click="openModal(variable)"
                           v-tooltip="i18n('Upload data')"/>
                        <i v-if="variable.type === 'liquidation_by_dependency'"
                           class="fas fa-calendar-check check-button"
                        @click="checkLiquidation(variable)"
                           v-tooltip="i18n('Liquidate')"/>
                        <i class="fas fa-pencil-alt"
                           v-if="variable.type === 'periodic_indicator'"
                        @click="checkRule(variable)"
                           v-tooltip="i18n('Liquidate')"/>
                        <i class="fas fa-check approve-button"
                           v-if="variable.type !== 'liquidation_by_dependency'"
                           @click="approve(period_selected, variable.id)"
                           v-tooltip="i18n('Approve')"/>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { VueSelect } from '@enso-ui/select/bulma';
import '@fortawesome/fontawesome-free/css/all.css';
import Index from '@enso-ui/data-import/src/bulma/pages/dataImport/Index.vue';
import { Tooltip, VTooltip } from 'v-tooltip';
import ImportCustom from './ImportCustom.vue';
import ModalComponent from '../modal/ModalComponent.vue';
import 'v-tooltip/dist/v-tooltip.css';

export default {
    name: 'TrafficLightIndex',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        ModalComponent, VueSelect, ImportCustom, Index, Tooltip,
    },
    directives: { tooltip: VTooltip },

    inject: ['http', 'route', 'i18n', 'toastr'],
    data() {
        return {
            period_selected: null,
            selectedVariable: null,
            selectedProgram: null,
            variables: [
                {
                    period_id: null,
                },
            ],
            variablesPeriodicity: [],
            periods: [],
        };
    },
    computed: {
        filteredVariables() {
            if (!this.period_selected) {
                return [];
            }

            const variableIds = [];
            this.variablesPeriodicity.forEach(vp => {
                if (vp.period_id === this.period_selected) {
                    variableIds.push(vp.variable_id);
                }
            });

            return this.variables.filter(variable => variableIds.includes(variable.id));
        },
    },
    watch: {
        period_selected() {
            this.fetchVariables();
        },
    },

    mounted() {
        this.fetchVariablePeriodicities();
        this.fetchPeriods();
    },
    methods: {
        approve(period, variable) {
            this.$router.push(`/liquidation/${variable}/approves/${period}`);
        },
        errorHandler(error) {
            console.error(error);
            this.toastr.error('An error occurred');
        },
        checkLiquidation(variable) {
            this.http
                .get(this.route('variable.checkLiquidation', { variable: variable.id }))
                .then(data => {
                    if (data.data.error) {
                        this.toastr.error(this.i18n(data.data.message));
                    } else if (data.data.message) {
                        this.toastr.success(this.i18n(data.data.message));
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        checkRule(variable) {
            this.http
                .get(this.route('variable.checkRule', { variable: variable.id, period: this.period_selected }))
                .then(response => {
                    const { data } = response;

                    if (data.length > 0) {
                        data.forEach(result => {
                            if (result.error) {
                                this.toastr.error(this.i18n(result.message));
                            } else if (result.message) {
                                this.toastr.success(this.i18n(result.message));
                            }
                        });
                    } else {
                        this.toastr.error('No data received');
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.toastr.error('An error occurred');
                });
        },
        fetchPeriods() {
            this.http
                .get(this.route('period.options'))
                .then(({ data }) => {
                    this.periods = data;
                })
                .catch(this.errorHandler);
        },
        fetchVariables() {
            this.http
                .get(this.route('variable.optionsForLiquidations', { variable: this.period_selected }))
                .then(({ data }) => {
                    this.variables = data;
                })
                .catch(this.errorHandler);
        },
        fetchVariablePeriodicities() {
            this.http
                .get(this.route('variable.variablePeriodicity'))
                .then(({ data }) => {
                    this.variablesPeriodicity = data.variablePeriodicities;
                })
                .catch(this.errorHandler);
        },
        openModal(variable) {
            this.selectedVariable = variable.id;
            this.selectedProgram = variable.program_id;
            this.$refs.modalComponent.openModal();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';
.approve-button{
    color: green;
    margin: 0 .5rem;
    font-size: 1.2rem;
}
.import-button{
    color: $blue;
    margin: 0 .5rem;
    font-size: 1.2rem;
}
.actions{
    text-align: center;
}

i{
    cursor: pointer;
}
.red-cell {
    background-color: $redSoft;
}

.green-cell {
    background-color: $limeGreen;
}
.yellow-cell{
    background-color: $yellow;
}
.blue {
    background-color: $blue;
    color: white;
}

.image-news-details{
    object-fit: cover;
}
.text-news{
    overflow: hidden;
    text-align: center;
}
.date{
    font-size: 12px;
    display: flex;
    justify-content: center;
}
table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}
.filter-periods {
    margin: auto;
    width: 50%;
}

th[colspan="2"] {
    background-color: #ffffff;
}
@media (max-width: 768px) {
    table {
        font-size: 0.74rem;
    }
}

</style>
