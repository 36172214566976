<template>
    <div>
        <TrafficLightIndex/>
    </div>
</template>

<script>
import TrafficLightIndex from '../../components/trafficLight/Table.vue';

export default {
    name: 'Index',
    components: { TrafficLightIndex },
    inject: ['i18n', 'route', 'http'],
};
</script>

<style scoped lang="scss">
</style>
