<template>
  <div class="wrapper">
      <div v-if="!periodSelected" class="card">
          <header class="card-header">
              <p class="card-header-title is-centered">
                  {{ i18n('Before importing, you need to choose a period') }}
              </p>
          </header>
      </div>
    <div class="columns is-variable is-2 is-mobile is-multiline">
      <div class="column is-3-desktop is-half-touch">
        <enso-select v-model="type"
                     :options="enums.importTypes._select()"
                     placeholder="Import Type"
                     @update:model-value="type ? template() : null"/>
      </div>
      <template v-if="type">
        <div v-for="param in params"
             class="column is-3-desktop is-half-touch"
             :key="param.name">
          <slot :name="param.name"
                v-if="param.type === 'slot'"/>
          <Param :param="param"
                 v-else/>
        </div>
        <div class="column"/>
        <div class="buttons-container">
          <a class="button is-info mr-2" :href="templateLink">
            <span>{{ i18n('Template') }}</span>
            <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'download']"/>
        </span>
          </a>
          <div v-if="periodSelected" class="column is-full-mobile">
            <div class="field">
              <div class="control">
                <label class="file-label">
                  <input class="file-input" type="file" accept="xlsx" @change="handleFileUpload">
                  <span class="file-cta">
                <span class="file-label">
                  {{ i18n('Import') }}
                    <font-awesome-icon :icon="['fas', 'upload']"/>
                </span>
              </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <enso-table class="box is-paddingless raises-on-hover"
                ref="liquidations"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faDownload, faTrashAlt, faFileExcel, faBan, faSync, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { EnsoTable } from '@enso-ui/tables/bulma';
import Param from '@enso-ui/data-import/src/bulma/pages/dataImport/components/Param.vue';
import { EnsoSelect } from '@enso-ui/select/bulma';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faDownload, faTrashAlt, faFileExcel, faBan, faSync, faUpload);

export default {
    name: 'ImportCustom',

    components: {
        EnsoSelect,
        EnsoTable,
        Param,
        FontAwesomeIcon,
    },

    inject: ['canAccess', 'errorHandler', 'http', 'i18n', 'route', 'toastr'],
    props: {
        periodSelected: {
            type: Number,
            required: true,
        },
        variable: {
            type: Number,
            required: true,
        },
        program: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        type: null,
        params: [],
    }),

    computed: {
        templateLink() {
            return this.canAccess('import.template')
            && this.route('import.template', this.type);
        },
        filteredPeriodId() {
            if (this.period_selected) {
                return [this.period_selected];
            }
            return [];
        },
        ...mapState(['enums']),
        filters() {
            return { data_imports: { type: this.type } };
        },
        importLink() {
            return this.canAccess('import.store')
          && this.type
          && this.route('import.store');
        },
        uploadParams() {
            return this.params.reduce((params, param) => {
                params[param.name] = param.value;
                return params;
            }, { type: this.type });
        },
    },

    methods: {
        template() {
            this.http.get(this.route('import.show', this.type))
                .then(({ data: { params } }) => (this.params = params))
                .catch(error => {
                    this.type = null;
                    this.errorHandler(error);
                });
        },
        rejected({ rejected_id: id }) {
            window.location.href = this.route('import.rejected', id);
        },
        reloadTableData() {
            if (this.ready && this.$refs.table) {
                this.$refs.table.fetch();
            }
        },
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
            const formData = new FormData();
            formData.append('import', this.file);
            formData.append('type', this.type);
            formData.append('period_id', this.periodSelected);
            formData.append('variable_id', this.variable);
            formData.append('program_id', this.program);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('import.store'), formData,
                { headers })
                .then(data => {
                    if (data.data.error) {
                        this.toastr.error(data.data.message);
                    } else if (data.data.message) {
                        this.toastr.success(data.data.message);
                    }
                    this.reloadTableData();
                }).catch(this.errorHandler);
        },
    },
};
</script>
<style>
.buttons-container {
  display: flex;
  align-items: center;
}
</style>
